declare global {
    interface Window {
        ronin: {
            provider: any;
        };
    }
}

import {
    Button,
    Flex,
    Spinner,
    Text,
    Image,
    useToast,
    useDisclosure
} from '@chakra-ui/react'
import { FaLinkSlash } from 'react-icons/fa6'
import type { Quest } from '@olagg/db-types'
import { QUEST_STATUS } from '@olagg/db-types'
import { OSName } from '../../../../../utils/operationSystem';
import s from './styles/ConnectButton.module.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { createThirdwebClient, defineChain } from "thirdweb";
import {
    ConnectButton,
    useActiveWalletConnectionStatus,
    useActiveAccount,
    useActiveWalletChain,
    useDisconnect,
    useActiveWallet
} from 'thirdweb/react';
import { createWallet as createThirdwebWallet, walletConnect } from "thirdweb/wallets";
import { mumbai, polygon, ethereum, mainnet, bsc, bscTestnet } from "thirdweb/chains";
import { ethers } from 'ethers';
import useQuest from '../../../hooks/useQuest'
import Countdown from './CountdownWeb3'
import IconClock from '../assets/icon_clock.svg'
import ModalWeb3Success from './ModalWeb3Success'
import ModalWeb3Error from './ModalWeb3Error'
import ModalWeb3UnsetWallet from './ModalWeb3UnsetWallet'

import moment from 'moment/moment.js'
import 'moment/min/locales'

type web3ButtonProps = {
    quest: Quest
    reloadQuests: () => Promise<void>
    disabled: boolean,
    loggedIn: boolean,
    openOnboarding: () => void
}

const QuestWeb3Button = ({
    quest,
    reloadQuests,
    disabled,
    loggedIn,
    openOnboarding
}: web3ButtonProps) => {
    const { t } = useTranslation();
    const toast = useToast()
    const { setVerifiedWallet, unsetVerifiedWallet, completeWeb3Quest, checkStatusWeb3Quest, claimQuest } = useQuest()
    const thirdwebClient = createThirdwebClient({
        clientId: import.meta.env.VITE_THIRDWEB_CLIENT_ID,
    });
    const { disconnect } = useDisconnect();
    const activeWallet = useActiveWallet();
    const wallets = () => {
        const wallets = [
            walletConnect()
        ];
        if (OSName() == 'macOS' || OSName() == 'Windows' || OSName() == 'Linux') {
            wallets.push(createThirdwebWallet("io.metamask"));
            wallets.push(createThirdwebWallet("com.roninchain.wallet"));
        }
        return wallets;
    }

    const status = useActiveWalletConnectionStatus();
    const lang = localStorage.getItem('i18n_lang') || navigator.language.slice(0, 2);
    const locale = lang === 'es' ? 'es_ES' : 'en_US'; // portuguese not available in thirdweb

    const ronin = defineChain({
        id: 2020,
        rpc: "https://api.roninchain.com/rpc",
        nativeCurrency: {
            name: "Ronin",
            symbol: "RON",
            decimals: 18,
        },
    });

    const chainInstances = [
        { value: "polygon", chainName: "Polygon", chain: polygon, chainId: 137, iconUrl: "https://cryptologos.cc/logos/polygon-matic-logo.png" },
        { value: "ethereum", chainName: "Ethereum", chain: ethereum, chainId: 1, iconUrl: "https://cryptologos.cc/logos/ethereum-eth-logo.png" },
        { value: "ronin", chainName: "Ronin", chain: ronin, chainId: 2020, iconUrl: "https://cryptologos.cc/logos/ronin-ron-logo.png" }
    ];

    const account = useActiveAccount();
    const activeWalletChain = useActiveWalletChain();

    const chainData = chainInstances.filter(c => c?.value == quest.network)
    const chain = chainData.length > 0 ? chainData[0].chain : null
    // const icon = chainData.length > 0 ? chainData[0].iconUrl : null
    const icon = quest?.blockchainToken?.network?.networkIconUrl

    const [canRetry, setCanRetry] = useState<boolean | null>(null)

    const convertToLocal = (utcTimestamp) => {
        return moment.utc(utcTimestamp).local().toLocaleString()
    };

    const CheckCanRetry = () => {
        if (!quest?.recursive) return true

        let timestamp = quest?.lastAttemptToComplete ? convertToLocal(quest?.lastAttemptToComplete) : '1970-01-01T00:00:00Z'
        let timestampLastCompletionDate = quest?.lastCompletionDate ? convertToLocal(quest?.lastCompletionDate) : '1970-01-01T00:00:00Z'

        if (timestamp > timestampLastCompletionDate) return true

        let seconds = Number(quest?.retryIn)
        const givenTime = new Date(timestamp).getTime(); // Convertir el timestamp a milisegundos
        const currentTime = Date.now(); // Obtener el tiempo actual en milisegundos

        return setCanRetry(currentTime >= givenTime + (seconds * 1000)) // Verificar si pasaron X segundos
    }

    const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false)
    const [isVerifyingWallet, setIsVerifyingWallet] = useState<boolean>(false)
    const [isWalletVerified, setIsWalletVerified] = useState<boolean>(quest?.address ? true : false)
    const [isVerifyingToken, setIsVerifyingToken] = useState<boolean>(false)
    const [isTokenVerified, setIsTokenVerified] = useState<boolean>(false)
    const [missingAmount, setMissingAmount] = useState<string>('')

    const pending = quest?.status === QUEST_STATUS.PENDING
    const alreadyClaimedOnce = (quest?.iteration || 1) > 1

    const isMobileDevice = () => {
        return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    const handleSignMessage = async () => {
        try {
            setIsVerifyingWallet(true)
            const message = `${t('Quests.web3.signWallet', { timestamp: Date.now() })}`
            let provider = window.ethereum

            if (chainData?.[0]?.value === 'ronin') {
                if (isMobileDevice()) {
                    await account.signMessage({ message: message });
                } else {
                    provider = new ethers.providers.Web3Provider(window.ronin.provider);
                    let signerRonin = provider.getSigner();
                    await signerRonin.signMessage(message);
                }
            } else {
                if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
                    provider = new ethers.providers.Web3Provider(window.ethereum);
                    let signerEthereum = provider.getSigner();
                    await signerEthereum.signMessage(message);
                } else {
                    await account.signMessage({ message: message });
                }
            }

            let saveWallet = await setVerifiedWallet(quest?.id, account?.address)

            if (saveWallet) setIsWalletVerified(true)
        } catch (error) {
            console.log('error', error);
            setIsVerifyingWallet(false)
            setIsWalletVerified(false)
        } finally {
            setIsVerifyingWallet(false)
        }
    };

    const checkCurrentChain = async () => {
        if (status == 'connected') {
            if ( // If the wallet is not the correct one for the chain, disconnect
                (activeWallet?.id === 'io.metamask' && chainData?.[0]?.value === 'ronin')
                || (activeWallet?.id === 'com.roninchain.wallet' && chainData?.[0]?.value !== 'ronin')
            ) {
                disconnect(activeWallet)
                setIsWalletConnected(false)
            }
            if (activeWalletChain.id == chain.id) {
                setIsWalletConnected(true)
            } else {
                if (activeWallet) disconnect(activeWallet)
                setIsWalletConnected(false)
            }
        } else {
            if (activeWallet) disconnect(activeWallet)
            setIsWalletConnected(false)
        }
    }

    useEffect(() => {
        checkCurrentChain()
    }, [status, activeWalletChain])

    const formatWithEllipsis = (str) => {
        if (str.length <= 10) return str
        const start = str.slice(0, 5)
        const end = str.slice(-4)
        return `${start}...${end}`
    };

    const handleVerifyToken = async () => {
        try {
            setIsVerifyingToken(true)
            let initDate = new Date()

            await completeWeb3Quest(quest?.id)

            let check = null;
            const maxRetries = 5;
            const delay = 5000;
            for (let attempt = 1; attempt <= maxRetries; attempt++) {
                check = await checkStatusWeb3Quest(quest?.id);

                if (quest?.recursive) {
                    if (check?.last_completion_date != null && check?.status === 'pending') {
                        const lastCompletionDate = new Date(check?.last_completion_date); // Fecha en UTC

                        const initDateUTC = initDate.getTime(); // 'ahora' ya se convierte automáticamente a UTC
                        const lastCompletionTime = lastCompletionDate.getTime(); // Ya está en UTC

                        const missing_amount = Number(check?.missing_amount)
                        if (lastCompletionTime > initDateUTC && missing_amount == 0) {
                            setIsVerifyingToken(false);
                            setIsTokenVerified(true);
                            onOpen();
                            return;
                        }
                    }

                } else {
                    if (check?.status === 'claimed' as QUEST_STATUS) {
                        setIsVerifyingToken(false);
                        setIsTokenVerified(true);
                        onOpen();
                        return;
                    }
                }

                if (attempt < maxRetries) {
                    await new Promise(resolve => setTimeout(resolve, delay));
                }
            }

            // Si llegamos aquí, los intentos fallaron
            setMissingAmount(check?.missing_amount)
            onOpenError()
            setIsVerifyingToken(false)
            setIsTokenVerified(false);

        } catch (error) {
            onOpenError()
            setIsTokenVerified(false)
        } finally {
            setIsVerifyingToken(false)
        }
    }

    const handleWeb3Button = () => {
        if (!loggedIn) {
            openOnboarding()
        } else {
            if (!isWalletVerified) {
                handleSignMessage()
            } else if (!isTokenVerified) {
                //Verify token and complete quest
                handleVerifyToken()
                CheckCanRetry()
            }
        }
    }

    const handleUnsetWallet = async () => {
        const walletUnset = await unsetVerifiedWallet(quest?.id)
        if (walletUnset) {
            setIsWalletVerified(false)
            setIsWalletConnected(false)
            if (activeWallet) disconnect(activeWallet)
        }
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure()
    const { isOpen: isOpenUnsetWallet, onOpen: onOpenUnsetWallet, onClose: onCloseUnsetWallet } = useDisclosure()

    useEffect(() => {
        CheckCanRetry()
    }, [])

    if (!canRetry && canRetry != null) {
        return (
            loggedIn ? (
                <Flex
                    mt='8px'
                    color='#AB17DF'
                    border='1px solid #AB17DF'
                    borderRadius={'6px'}
                    alignItems='center'
                    justifyContent={'center'}
                    h='40px'
                    flexDirection={'row'}
                    gap='4px'
                    w='100%'
                >
                    <Image src={IconClock} />
                    <Text fontWeight={'400'}>{t("Quests.status.availableIn")}</Text>
                    <Text fontWeight={'700'}><Countdown timestamp={quest?.lastAttemptToComplete || '1970-01-01T00:00:00Z'} seconds={Number(quest?.retryIn)} onExpired={CheckCanRetry} /></Text>
                </Flex>
            ) : (
                <Button
                    variant={'filledGradient'}
                    w={'full'}
                    color={'white'}
                    fontSize="14px"
                    onClick={openOnboarding}
                >
                    {t('ConnectWallet.ConnectButtons.mainButton.label')}
                </Button>
            )
        )
    }

    return (
        <>
            {(quest?.address || (account?.address && isWalletConnected)) && loggedIn && (
                <Flex
                    bgColor='#F2EDFF'
                    p='4px 8px'
                    borderRadius={'4px'}
                    gap='8px'
                    mt='12px'
                    mb='8px'
                    w='100%'
                >
                    <Flex border={'1px solid #4E10FF'} w='24px' h='24px' borderRadius='100px' alignItems={'center'} justifyContent={'center'}>
                        <Image src={icon} width='16px' height='16px' />
                    </Flex>
                    <Text color='#0C0324' fontSize='14px'>
                        {formatWithEllipsis(quest?.address ? quest?.address : account?.address)}
                    </Text>
                    {(pending && !alreadyClaimedOnce && isWalletVerified) && (
                        <Flex ml='auto' cursor='pointer' onClick={onOpenUnsetWallet}>
                            <FaLinkSlash color='#4E10FF' size='24px' />
                        </Flex>
                    )}
                </Flex>
            )}


            {!quest?.address && !isWalletConnected && loggedIn ? (
                <>
                    <ConnectButton
                        appMetadata={{
                            name: 'Ola GG',
                            url: 'https://olagg.io'
                        }}
                        client={thirdwebClient}
                        wallets={wallets()}
                        chain={chain}
                        showAllWallets={false}
                        locale={locale}
                        walletConnect={{
                            projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID
                        }}
                        connectButton={{
                            label: t('ConnectWallet.ConnectButtons.mainButton.label'),
                            className: s.connectButton,
                        }}
                        connectModal={{
                            showThirdwebBranding: false,
                        }}
                        detailsButton={{
                            className: s.detailsButton,
                        }}
                        detailsModal={{
                            hideBuyFunds: true,
                            hideSwitchWallet: true,
                            showTestnetFaucet: false,
                        }}
                        switchButton={{
                            label: t('ConnectWallet.ConnectButtons.mainButton.label'),
                            className: s.connectButton,
                        }}
                    />
                </>
            ) : (

                !isTokenVerified && (
                    <Button
                        variant={'filledGradient'}
                        w={'full'}
                        color={'white'}
                        fontSize="14px"
                        onClick={() => handleWeb3Button()}
                        disabled={(isVerifyingWallet || isVerifyingToken) || (disabled && loggedIn)}
                    >
                        {(isVerifyingWallet || isVerifyingToken) ? (
                            <Flex gap='8px' alignItems={'center'}>
                                <Spinner speed="0.85s" size={'xs'} />
                                {isVerifyingWallet && t('Quests.web3.verifyingWallet')}
                                {isVerifyingToken && t('Quests.web3.verifyingToken')}
                            </Flex>
                        ) : (
                            !isWalletVerified && !quest?.address ?
                                t('Quests.web3.verifyWallet')
                                : !isTokenVerified ?
                                    t('Quests.web3.verifyToken')
                                    : t("Quests.card.completed")
                        )}
                    </Button>
                )
            )}

            <ModalWeb3Success isOpen={isOpen} onClose={onClose} quest={quest} reloadQuests={reloadQuests} />
            <ModalWeb3Error isOpen={isOpenError} onClose={onCloseError} quest={quest} missingAmount={missingAmount} reloadQuests={reloadQuests} />
            <ModalWeb3UnsetWallet
                isOpen={isOpenUnsetWallet}
                onClose={onCloseUnsetWallet}
                icon={icon}
                address={quest?.address ?? account?.address}
                handleUnsetWallet={handleUnsetWallet}
                reloadQuests={reloadQuests}
            />
        </>
    )
}

export default QuestWeb3Button
